import React, { useState, useEffect, useRef } from 'react'
import { PageProps, Link, graphql, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import "./style.scss"
import Logo from "images/logo.png"
import MobileDropdown from 'components/navigation/mobile-navigation.tsx'

type DataProps = {
  site: {
    buildTime: string
  }
  location: Location
}

const Header: React.FC<PageProps<DataProps>> = ({ path }) => {
  const data = useStaticQuery(
    graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `
  )

  const logo = getImage(data.logo);


  return (
    <nav className="navbar navbar-expand navbar-light flex-column flex-md-row" id="main-navigation">
      <div className="container">
        <div className="hidden-md-up">
          <MobileDropdown />
        </div>
        {/* <div className="col-lg-2">
          <Link className="text-center" to="/">
            <img src={Logo} id="logo" alt="peer club logo"/>
          </Link>

        </div> */}
        <div className="col-lg-12">
          <div className="navbar-nav-scroll hidden-md-down">
            <ul className="navbar-nav bd-navbar-nav justify-content-center">
              <li
              // className={
              //   location.pathname === '/'
              //     ? 'nav-item active'
              //     : 'nav-item'
              // }
              >
                <a href="/ " className="nav-link">
                  Home
                </a>
              </li>
              <li
              // className={
              //   location.pathname === '/'
              //     ? 'nav-item active'
              //     : 'nav-item'
              // }
              >
                <a href="/#why-join-us" className="nav-link">
                  Why Join Us
                </a>
              </li>
              <li
              // className={
              //   location.pathname === '/'
              //     ? 'nav-item active'
              //     : 'nav-item'
              // }
              >
                <a href="/#how-it-works" className="nav-link">
                  How It Works
                </a>
              </li>
              <li
              //     className={
              //     location.pathname === '/'
              //       ? 'nav-item active'
              //       : 'nav-item'
              // }
              >
                <a href="/#our-privacy-promise" className="nav-link">
                  Privacy
                </a>
              </li>
              <li
                //     className={
                //     location.pathname === '/'
                //       ? 'nav-item active'
                //       : 'nav-item'
                // }
              >
                <a href="/terms-and-conditions" target="_blank" className="nav-link">
                  Terms and Conditions
                </a>
              </li>
              <li
              //     className={
              //     location.pathname === '/'
              //       ? 'nav-item active'
              //       : 'nav-item'
              // }
              >
                <a href="/#time-investment" className="nav-link">
                  Time Investment
                </a>
              </li>
              <li
              //     className={
              //     location.pathname === '/'
              //       ? 'nav-item active'
              //       : 'nav-item'
              // }
              >
                <a href="/#cost" className="nav-link">
                  Cost
                </a>
              </li>
              <li
              //     className={
              //     location.pathname === '/'
              //       ? 'nav-item active'
              //       : 'nav-item'
              // }
              >
              </li>
              {/* <a href="https://peer-club-sp3.web.app/" target="_blank">
              <button className="sp-btn">SOLUTIONS PROVIDER</button>
              </a> */}
              <li
              // className={
              //   location.pathname === '/'
              //     ? 'nav-item active'
              //     : 'nav-item'
              // }
              >
                {/* <Link to="#">
                  <button className="primary-btn">JOIN NOW</button>
                </Link> */}
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
      </div>
    </nav>
  )

}

export default Header
